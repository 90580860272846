import React from 'react';
import Layout from '../components/App/Layout';
import Navbar from '../components/App/Navbar';
import PageBanner from '../components/Common/PageBanner';
import Footer from '../components/App/Footer';

import { AnchorLink } from 'gatsby-plugin-anchor-links';

const PrivacyPolicy = () => {
  return (
    <Layout>
      <div className='darknav'>
        <Navbar />
      </div>
      <PageBanner pageTitle='Privacy Policy' homePageText='Home' homePageUrl='/' activePageText='Privacy Policy' />
      <section className='privacy-policy-area pb-100'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className='privacy-policy-content'>
                <ul className='hidden'>
                  <li>
                    <AnchorLink to='/privacy-policy/#welcome' title='Welcome to ekko&rsquo;s Privacy Policy'>
                      Welcome to ekko's Privacy Policy
                    </AnchorLink>
                  </li>
                  <li>
                    <AnchorLink to='/privacy-policy/#personal-info' title='Personal Information Collected'>
                      Personal Information Collected
                    </AnchorLink>
                  </li>
                  <li>
                    <AnchorLink to='/privacy-policy/#info-sharing' title='Information Sharing'>
                      Information Sharing
                    </AnchorLink>
                  </li>

                  <li>
                    <AnchorLink to='/privacy-policy/#personal-info-sharing' title='Personal Information Security'>
                      Personal Information Security
                    </AnchorLink>
                  </li>
                  <li>
                    <AnchorLink to='/privacy-policy/#legal-processing-basis' title='Legal Processing Basis'>
                      Legal Processing Basis
                    </AnchorLink>
                  </li>
                  <li>
                    <AnchorLink to='/privacy-policy/#cookie-usage' title='Cookie Usage and PII'>
                      Cookie Usage and PII
                    </AnchorLink>
                  </li>
                  <li>
                    {' '}
                    <AnchorLink to='/privacy-policy/#childrens-privacy' title='Children&rsquo;s Privacy'>
                      Children&rsquo;s Privacy
                    </AnchorLink>
                  </li>
                  <li>
                    <AnchorLink to='/privacy-policy/#GDPR' title='GDPR Compliance at ekko'>
                      GDPR Compliance at ekko
                    </AnchorLink>
                  </li>
                  <li>
                    <AnchorLink to='/privacy-policy/#CCPA' title='CCPA Compliance at ekko'>
                      CCPA Compliance at ekko
                    </AnchorLink>
                  </li>
                  <li>
                    <AnchorLink to='/privacy-policy/#contact' title='Contact Information'>
                      Contact Information
                    </AnchorLink>
                  </li>
                </ul>
                <h3>Welcome to ekko’s Privacy Policy</h3>
                <p>
                  <a id='welcome'>
                    ekko Consulting Inc. (ekko) respects your right to privacy. We are committed to protecting
                    personally identifiable information (PII) you may provide us through the Services. We have adopted
                    this privacy policy (“Privacy Policy”) to explain what information may be collected through our
                    Services, how we may use this information, and under what circumstances we may disclose such
                    information to third parties. This Privacy Policy applies only to information we collect through the
                    Services. This Privacy Policy explains how we collect, store, process, share, and use personal
                    information relating to you, and how you can exercise your privacy rights.
                  </a>
                </p>
                <h3>Personal Information Collected</h3>
                <p>
                  <a id='personal-info'>
                    Certain users of ekko's Services choose to interact with the Services in ways that require ekko to
                    gather personally-identifying information. This can include information you provide to us when you
                    register for an account, fill in a form on our Website, create or edit your user profile, correspond
                    with us by phone, e-mail or otherwise, subscribe to our mailing lists, newsletters or other forms of
                    marketing communications, respond to a survey, enter a promotion or use some other feature of our
                    products or services. The following provides a summary of the personal information attributes
                    collected:
                  </a>
                </p>
                <blockquote className='blockquote'>
                  <table className='table' style={{ fontSize: '12px' }}>
                    <thead>
                      <tr>
                        <th style={{ borderTop: 0 }} scope='col'>
                          Personal Information Attribute
                        </th>
                        <th style={{ borderTop: 0 }} scope='col'>
                          Purpose for Collection
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>First name, Last Name</td>
                        <td>Registration on ekko platform/service</td>
                      </tr>
                      <tr>
                        <td>Business Email</td>
                        <td>Registration on ekko platform/service</td>
                      </tr>
                      <tr>
                        <td>Company Address</td>
                        <td>Registration on ekko platform/service</td>
                      </tr>
                      <tr>
                        <td>User ID/Email</td>
                        <td>Login to the ekko platform</td>
                      </tr>
                    </tbody>
                  </table>
                </blockquote>
                <h3>Information Sharing</h3>
                <p>
                  <a id='info-sharing'>ekko may share personal information in the following ways: </a> 
                </p>
                <ul>
                  <li>Within ekko for purposes of data processing or storage;</li>

                  <li>
                    With business partners, service vendors service or transaction (examples include, but are not
                    limited to: processing of orders and credit card transactions, hosting websites, hosting seminar
                    registration, assisting with sales-related efforts or post-sales support, and providing customer
                    support);
                  </li>

                  <li>
                    In response to a request for information by a competent authority if we believe disclosure is in
                    accordance with or is otherwise required by, any applicable law, regulation or legal process;
                  </li>

                  <li>
                    With law enforcement officials, government authorities, or other third parties as necessary to
                    comply with legal process or meet national security requirements, to protect the rights, property,
                    or safety of ekko, its business partners, you, or others, or as otherwise required by applicable
                    law;
                  </li>

                  <li>In aggregated and/or anonymized form which cannot reasonably be used to identify you; and</li>

                  <li>If we otherwise notify you and you consent to such sharing.</li>
                </ul>
                <h3>Personal Information Security</h3>
                <p>
                  <a id='personal-info-sharing'>
                    We take the security of your personal information very seriously. We use appropriate security,
                    technical and organizational measures to protect the security of the personal information that we
                    collect and process about you from unauthorized disclosure. The measures we use are designed to
                    provide a level of security appropriate to the risk of processing your personal information.
                  </a>
                </p>
                <h3>Legal Processing Basis</h3>
                <p>
                  <a id='legal-processing-basis'>
                    Our legal basis for collecting and using the personal information described above will depend on the
                    personal information collected and the specific context in which we collect it.
                  </a>
                </p>
                <p>
                  We will normally collect personal information from you only where we have your consent to do so, where
                  we need the personal information to perform a contract with you, or where the processing is in our
                  legitimate interests and not overridden by your data protection interests or fundamental rights and
                  freedoms.
                </p>
                <p>
                  If you have questions about or need further information concerning the legal basis on which we collect
                  and use your personal information, please contact us at{' '}
                  <a href='mailto:ekko-info@ekkoconsulting.com' target='_blank'>
                    {' '}
                    ekko-info@ekkoconsulting.com
                  </a>
                </p>
                <h3>Cookie Usage and PII</h3>
                <p>
                  <a id='cookie-usage'>
                    To enrich and perfect your online experience, ekko uses “Cookies”, similar technologies and services
                    provided by others to display personalized content, appropriate advertising and store your
                    preferences on your computer.
                  </a>
                </p>
                <p>
                  A cookie is a string of information that a website stores on a visitor's computer, and that the
                  visitor's browser provides to the website each time the visitor returns. ekko uses cookies to help
                  ekko identify and track visitors and their Website access preferences. ekko visitors who do not wish
                  to have cookies placed on their computers should set their browsers to refuse cookies before using
                  ekko's Websites, with the drawback that certain features of ekko's Websites may not function properly
                  without the aid of cookies.
                </p>
                <p>
                  By continuing to navigate our Website without changing your cookie settings, you hereby acknowledge
                  and agree to ekko's use of cookies.
                </p>
                <h3>Children's Privacy</h3>
                <p>
                  <a id='childrens-privacy'>
                    <strong>ekko</strong> does not knowingly collect personal information from children or distinguish
                    visitor's to the <strong>ekko</strong> website or platform. If you believe that we may have
                    collected personal information from someone under the applicable age of consent please let us know
                    by emailing{' '}
                  </a>
                  <a href='mailto:ekko-info@ekkoconsulting.com' title='email us'>
                    {' '}
                    ekko-info@ekkoconsulting.com
                  </a>
                </p>
                <h3>GDPR Compliance at ekko</h3>
                <p>
                  <a id='GDPR'>
                    In accordance with the European privacy laws, EEA and Swiss data subjects have the following data
                    protection rights. If you wish to access, correct, update or request deletion of your personal data,
                    you can do so at any time by contacting us at{' '}
                  </a>
                  <a href='mailto:ekko-info@ekkoconsulting.com' title='email us'>
                    {' '}
                    ekko-info@ekkoconsulting.com
                  </a>
                </p>
                <ul>
                  <li>
                    If you wish to access, correct, update or request deletion of your personal information, you can do
                    so at any time by contacting us at{' '}
                    <a href='mailto:ekko-info@ekkoconsulting.com' title='email us'>
                      {' '}
                      ekko-info@ekkoconsulting.com
                    </a>
                  </li>

                  <li>
                    In addition, you can object to processing of your personal information, ask us to restrict
                    processing of your personal information or request portability of your personal information. Again,
                    you can exercise these rights by contacting us at{' '}
                    <a href='mailto:ekko-info@ekkoconsulting.com' title='email us'>
                      {' '}
                      ekko-info@ekkoconsulting.com
                    </a>
                  </li>

                  <li>You have the right to opt-out of marketing communications we send you at any time.</li>

                  <li>
                    Similarly, if we have collected and processed your personal information with your consent, you can
                    withdraw your consent at any time. Withdrawing your consent will not affect the lawfulness of any
                    processing we conducted prior to your withdrawal, nor will it affect processing of your personal
                    information conducted in reliance on lawful processing grounds other than consent.
                  </li>

                  <li>
                    You have the right to complain to a data protection authority about our collection and use of your
                    personal information. For more information, please contact your local data protection authority.
                  </li>
                </ul>
                <h3>CCPA Compliance at ekko</h3>
                <p>
                  <a id='CCPA'>
                    As a California business, ekko complies with the California Consumer Privacy Act (“CCPA”).
                  </a>
                </p>
                <p>Under the CCPA, you may have the following consumer rights:</p>
                <ul>
                  <li>
                    The right to know about the personal information a business collects about you and how it is used
                    and shared - You may request that businesses disclose to you what personal information they have
                    collected, used, shared, or sold about you, and why they collected, used, shared, or sold that
                    information;
                  </li>
                  <li>
                    The right to delete personal information collected from you (with some exceptions) - You may request
                    that businesses delete personal information they collected from you and to tell their service
                    providers to do the same;
                  </li>
                  <li>
                    The right to opt-out of the sale of your personal information - You may request that businesses stop
                    selling your personal information (“opt-out”). With some exceptions, businesses cannot sell your
                    personal information after they receive your opt-out request unless you later provide authorization
                    allowing them to do so again; and
                  </li>
                  <li>
                    The right to non-discrimination for exercising your CCPA rights - Businesses cannot deny goods or
                    services, charge you a different price, or provide a different level or quality of goods or services
                    just because you exercised your rights under the CCPA.
                  </li>
                </ul>
                <p>Categories of Personal Information Collected by ekko:</p>
                <blockquote className='blockquote'>
                  <table className='table' style={{ fontSize: '12px' }}>
                    <tbody>
                      <tr style={{ borderTop: 0 }}>
                        <td>Category A: Identifiers</td>
                        <td>
                          Real name, postal address, unique personal identifier, online identifier, Internet Protocol
                          address, email address or other similar identifiers.
                        </td>
                      </tr>
                      <tr style={{ borderTop: 0 }}>
                        <td>
                          Category B: Personal information listed in § 1798.80 of the California Customer Records
                          statute
                        </td>
                        <td>
                          A name, signature, business address, telephone number, billing and transactions information.
                        </td>
                      </tr>
                      <tr>
                        <td>Category D: Commercial Information</td>
                        <td>Records and history of products or services purchased or considered.</td>
                      </tr>
                      <tr>
                        <td>Category F: Internet or other electronic network activity information</td>
                        <td>Interaction with our Services, applications, or advertisements.</td>
                      </tr>
                      <tr>
                        <td>Category G: Geolocation Data</td>
                        <td>Approximate physical location (derived from an Internet Protocol address)</td>
                      </tr>
                      <tr>
                        <td>Category I: Professional or employment-related information</td>
                        <td>Job Title, Company Name</td>
                      </tr>
                    </tbody>
                  </table>
                </blockquote>
                <h3>Contact Information</h3>
                <p>
                  {' '}
                  <a id='contact'>
                    If you have any questions or concerns about our use of your personal information, please contact us{' '}
                  </a>
                  <a href='mailto:ekko-info@ekkoconsulting.com' title='email us'>
                    {' '}
                    ekko-info@ekkoconsulting.com
                  </a>
                </p>{' '}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default PrivacyPolicy;
